import React from "react"
import DefaultLayout from "../layouts/DefaultLayout";
import ActivityCard from "../components/ActivityCard";
import { graphql } from "gatsby";

const crumbs = [
  {
    title: 'Αρχική',
    url: '/'
  },
  {
    title: 'Γνωρίστε Μας'
  },
  {
    title: 'Η Δράση Μας'
  }];

export default function OurActivities({data}) {
  let activities =
    data.allBlog.edges
      .map(edge => edge.node)
      .map(activity => {
        activity.date = new Date(activity.date);
        return activity;
      })
      .sort((a1,a2) => a2.date - a1.date);

  return <DefaultLayout title='Η Δράση Μας' crumbs={crumbs}>
    <div className='row content-section'>
      <div className={'col-md'} style={{fontFamily: '"fira sans", sans-serif'}}>
        <h5>Τι κάνει το REAL Science ξεχωριστό;</h5>

        <p>Καθώς το R.E.A.L. Science ξεκίνησε αρχικά ως μια πρωτοβουλία με χαρακτήρα
          αμιγούς hard science έχει στον πυρήνα του την επιπλέον παράμετρο να
          ενδυναμώσει και να εμπνεύσει τα κορίτσια να αποκτήσουν την αυτοπεποίθηση
          που χρειάζονται για να ασχοληθούν με τις λεγόμενες «θετικές» επιστήμες από νεαρή
          ηλικία. Επίσης, λαμβάνει υπ’όψιν του το αναλυτικό πρόγραμμα και βοηθάει κάθε
          μαθητή και μαθήτρια σε όλη την Ελλάδα να αξιοποιήσουν την ευρύτητα των
          γνώσεων που λαμβάνουν από τους εκπαιδευτικούς τους στο σχολείο. Τέλος,
          πρόκειται για μία αμιγώς εθελοντική πρωτοβουλία. Οι εθελοντές-επιστήμονες
          βρίσκονται στο REAL Science γιατί επιθυμούν να μεταδώσουν την εμπειρία τους και
          να μοιραστούν συμβουλές με τους μαθητές λαμβάνοντας την ικανοποίηση ότι με
          κάποιον τρόπο συνέβαλαν, απ’ όπου κι αν βρίσκονται, στο να ανοιχθούν νέοι
          “δρόμοι” στην Ελλάδα για τα παιδιά και τους νέους στα πρώτα βήματα της
          επαγγελματικής τους σταδιοδρομίας.</p>
      </div>
    </div>
    <div className='row content-section'>
      <div className={'col-md'} style={{fontFamily: '"fira sans", sans-serif'}}>
        <h5>Οι δράσεις μας ως τώρα:</h5>
      </div>
    </div>
    <div className='row content-section'>
      {
        activities.map(activity =>
          <div className={'col-lg-4 col-md-6'} style={{fontFamily: '"fira sans", sans-serif', paddingTop: '10px'}}>
            <ActivityCard image={activity.image} headline={activity.headline}
                          description={activity.description} link={activity.id}
                          date={activity.date}/>
          </div>
        )
      }
    </div>
  </DefaultLayout>
}

export const query = graphql`
  query {
    allBlog {
      edges {
        node {
          id
          image
          headline
          description
          links {
            title
            url
          }
          date
        }
      }
    }
  }
`