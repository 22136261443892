import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";


const styles = {
  card: {
    margin: "5px 20px"
  },
  media: {
    height: 140
  }
};

function formatDate(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  return (day < 10 ? '0' : '') + day + '/' +
    (month < 10 ? '0' : '') + month + '/' +
    (year - 2000);
}

function ActivityCard(props) {
  const { classes, image, headline, description, link, date } = props;
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia className={classes.media} image={image} title={headline} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" style={{height: 100}}>
            {headline}
          </Typography>
          <Typography component="p" style={{height: 110}}>{description}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Link to={'/blogs/' + link}>
          <Button size="small" color="primary">
            ΠΕΡΙΣΣΟΤΕΡΑ
          </Button>
        </Link>
        <div>{formatDate(date)}</div>
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(ActivityCard);